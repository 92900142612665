import type { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import {
  TenderData,
  TenderDataHistory,
  TenderDataLots,
  DocumentRevision,
  Document,
  Unit,
  OutputType,
  DocumentTypeOptions,
  TenderDataBasic,
  TenderDataCreate,
  DocumentCreate,
  TenderDataUpdate,
  DocumentPatch,
  TenderDataDocumentInfo,
  DocumentFolderCreate,
  DocumentFolder,
  UploadedDocumentCreate,
  UploadedDocument,
  UploadedDocumentUpdate,
  UploadedDocumentFileUpdate,
  DocumentVersion,
  DocumentPackageCreate,
  DocumentPackage,
  BasicDocument,
} from "@/types/tenderData";
import { createAndSetupAxios } from "@/helpers/setupAxios";
import type {
  CombinedCriterionCreate,
  CriterionGroup,
  CriterionGroupCreate,
  CriterionGroupUpdate,
  ProductGroup,
} from "@/types/criterionGroup";
import type {
  BulletPoint,
  BulletPointCreate,
  BulletPointUpdate,
  Position,
  PositionCreate,
  PositionUpdate,
} from "@/types/position";
import type {
  CriterionCreate,
  Criterion,
  CriterionUpdate,
} from "@/types/criterion";
import downloadFile from "@/helpers/downloadFile";
import type { LotCreate, Lot, LotUpdate } from "@/types/lot";
import type { User } from "@/types/user";
import { diff } from "@/helpers/diff";
import { has, isEmpty } from "es-toolkit/compat";
import { omit } from "es-toolkit";
import createObjectURL from "@/helpers/createObjectURL";
import type { FontOption } from "@/types/documents";
import { getDownloadName } from "@/helpers/fileName";
import { useFeatureStore } from "@/stores/feature";
import { AssistantService } from ".";
import {
  CreateDocumentFromTemplate,
  CreateDocumentTemplateFromDocument,
  DocumentTemplateListItem,
  DocumentTemplatePreview,
  UpdateDocumentTemplate,
} from "@/types/documentTemplates";

const $axios = createAndSetupAxios(import.meta.env.VITE_DESCRIPTIONS_ROUTE);

class DescriptionsService {
  // tender data
  async getTenderDatas(): Promise<TenderDataBasic[]> {
    const response = await $axios.get(`tender_data/`);
    return response.data;
  }

  async getTenderDatasDocumentInfo(params?: {
    skip?: number;
    limit?: number;
  }): Promise<TenderDataDocumentInfo[]> {
    const response = await $axios.get(`tender_data/document_info/`, { params });
    return response.data;
  }

  async getTenderDatasLots(): Promise<TenderDataLots[]> {
    const response = await $axios.get(`tender_data/lots/`);
    return response.data;
  }

  async getTenderData(id: string): Promise<TenderData> {
    const response = await $axios.get(`tender_data/${id}/`);

    return addIdsToTenderData(response.data);
  }

  getTenderDataHistory(id: string): Promise<TenderDataHistory[]> {
    return $axios
      .get(`tender_data/${id}/history/`)
      .then((response) => response.data);
  }

  async createTenderData(data: TenderDataCreate): Promise<TenderData> {
    const response = await $axios.post("tender_data/", {
      ...data,
      ...(useFeatureStore().flags.tender_data && { version: 2 }),
    });

    return addIdsToTenderData(response.data);
  }

  async copyTenderData(data: {
    id: string;
    name: string;
  }): Promise<TenderData> {
    const { id, name } = data;
    const response = await $axios.post("tender_data/copy/", { id, name });
    if (useFeatureStore().flags.service_description_context) {
      await AssistantService.copyReferenceDocuments(id, response.data.id);
    }

    return addIdsToTenderData(response.data);
  }

  async updateTenderData(
    tenderData: TenderDataUpdate,
    old?: TenderDataUpdate,
  ): Promise<TenderData> {
    const response = await $axios.patch(
      `tender_data/${tenderData.id}/`,
      diff(tenderData, old),
    );

    return addIdsToTenderData(response.data);
  }

  deleteTenderData(tenderData: TenderDataUpdate): Promise<void> {
    return $axios.delete(`tender_data/${tenderData.id}/`);
  }

  /** @deprecated feature no longer in use */
  changeTenderDataProcurement(data: {
    procurement_id: number;
    tender_data_id: number;
  }): Promise<TenderData> {
    return $axios
      .patch(`tender_data/${data.tender_data_id}/change_procurement_id/`, data)
      .then((response) => response.data);
  }

  async enableLotSplit(tenderData: TenderDataUpdate): Promise<TenderData> {
    return $axios
      .patch(`tender_data/${tenderData.id}/enable_lot_split/`)
      .then((response) => addIdsToTenderData(response.data));
  }

  async disableLotSplit(tenderData: TenderDataUpdate): Promise<TenderData> {
    return $axios
      .patch(`tender_data/${tenderData.id}/disable_lot_split/`)
      .then((response) => addIdsToTenderData(response.data));
  }

  async updateTenderDataFavorite(
    tenderDataID: string,
    favorite: boolean,
  ): Promise<TenderData> {
    return $axios
      .patch(
        `tender_data/${tenderDataID}/update_favorite/?favorite=${favorite}`,
      )
      .then((response) => addIdsToTenderData(response.data));
  }

  async shareTenderData(
    tenderDataID: string,
    newEditors: User[],
  ): Promise<TenderData> {
    return $axios
      .patch(`tender_data/${tenderDataID}/update_editors/`, newEditors)
      .then((response) => addIdsToTenderData(response.data));
  }

  /** @deprecated feature no longer in use */
  async addTenderId(
    tenderDataID: string,
    tender_id: string,
  ): Promise<TenderData> {
    return $axios
      .patch(`tender_data/${tenderDataID}/add_tender_id/`, { tender_id })
      .then((response) => response.data);
  }

  async getDocumentTypesByOutputType(
    tenderDataId: string,
    outputType: OutputType,
  ): Promise<DocumentTypeOptions[]> {
    const response = await $axios.get(
      `tender_data/${tenderDataId}/documents/document_types/${outputType}/`,
    );

    return response.data;
  }

  async createDocument(data: DocumentCreate): Promise<Document> {
    const response = await $axios.post(
      `tender_data/${data.tender_data_id}/documents/`,
      data,
    );

    return response.data;
  }

  async createUploadedDocument(
    data: UploadedDocumentCreate,
    config: AxiosRequestConfig<FormData> = {},
  ): Promise<UploadedDocument> {
    const formData = new FormData();
    formData.append("name", data.name);
    if (data.download_name)
      formData.append("download_name", data.download_name);
    formData.append("folder_id", data.folder_id ?? "");
    formData.append("file", data.file);
    if (data.marked_for_document_package !== null) {
      formData.append(
        "marked_for_document_package",
        data.marked_for_document_package,
      );
    }

    const response = await $axios.post(
      `tender_data/${data.tender_data_id}/documents/upload/`,
      formData,
      config,
    );

    return response.data;
  }

  async updateUploadedDocumentFile(
    data: UploadedDocumentFileUpdate,
    config: AxiosRequestConfig<FormData> = {},
  ): Promise<UploadedDocument> {
    const formData = new FormData();
    formData.append("file", data.file);

    const response = await $axios.post(
      `tender_data/${data.tender_data_id}/documents/uploaded/${data.id}/upload_version/`,
      formData,
      config,
    );

    return response.data;
  }

  async getDocumentData(tenderData: TenderData, documentId: string) {
    const sd = await this.getTenderData(tenderData.id);
    return sd.documents.find((doc) => doc.id === documentId);
  }

  async patchDocument(
    tenderData: TenderData,
    document: DocumentPatch,
  ): Promise<Document> {
    const response = await $axios.patch(
      `tender_data/${tenderData.id}/documents/${document.id}/`,
      document,
    );

    return response.data;
  }

  async updateUploadedDocument(
    tenderData: TenderData,
    document: UploadedDocumentUpdate,
  ): Promise<UploadedDocument> {
    const response = await $axios.patch(
      `tender_data/${tenderData.id}/documents/uploaded/${document.id}/`,
      document,
    );

    return response.data;
  }

  async getUploadedDocument(
    tenderData: TenderData,
    document: UploadedDocument,
  ): Promise<AxiosResponse<Blob, unknown>> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/uploaded/${document.id}/`,
      { responseType: "blob" },
    );

    return response;
  }

  async downloadUploadedDocument(
    tenderData: TenderData,
    document: UploadedDocument,
  ): Promise<void> {
    const response = await this.getUploadedDocument(tenderData, document);

    downloadFile(
      response,
      `${getDownloadName(document)}_${new Date().toLocaleString()}.${document.type}`,
    );
  }

  async deleteDocument(
    tenderData: TenderData,
    document: Document,
  ): Promise<TenderData> {
    const response = await $axios.delete(
      `tender_data/${tenderData.id}/documents/${document.id}/`,
    );

    return response.data;
  }

  async deleteUploadedDocument(
    tenderData: TenderData,
    document: UploadedDocument,
  ): Promise<TenderData> {
    const response = await $axios.delete(
      `tender_data/${tenderData.id}/documents/uploaded/${document.id}/`,
    );

    return response.data;
  }

  async deleteAllDeletedDocuments(tenderData: TenderData): Promise<TenderData> {
    const response = await $axios.delete(
      `tender_data/${tenderData.id}/deleted_documents/`,
    );

    return response.data;
  }

  async copyDocument(
    tenderData: TenderData,
    document: { id: string; name: string },
  ): Promise<Document> {
    const response = await $axios.post(
      `tender_data/${tenderData.id}/documents/copy/`,
      document,
    );

    return response.data;
  }

  async copyUploadedDocument(
    tenderData: TenderData,
    document: { id: string; name: string },
  ): Promise<UploadedDocument> {
    const response = await $axios.post(
      `tender_data/${tenderData.id}/documents/uploaded/copy/`,
      document,
    );

    return response.data;
  }

  async getDocumentRevisionAndTitle(
    tenderData: TenderData,
    document: Document,
    revision: DocumentRevision,
    requestType: string,
    responseType: ResponseType,
    index: number,
  ): Promise<[AxiosResponse<Blob>, string]> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/revision/${revision.id}/${requestType}/`,
      { responseType },
    );
    const title =
      `${tenderData.name}_${getDownloadName(document)}_Revision_${index}_${new Date(
        revision.created_at,
      ).toLocaleString()}`
        .replaceAll("/", "_")
        .replaceAll(":", "_");

    return [response, title];
  }

  async getDocument(
    tenderData: TenderData,
    document: Document,
    requestType: string,
    responseType: ResponseType,
  ): Promise<[AxiosResponse<Blob, unknown>, string]> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/${document.id}/${requestType}/`,
      { params: { draft: !useFeatureStore().flags.tender_data }, responseType },
    );
    const title =
      `${getDownloadName(document)}${useFeatureStore().flags.tender_data ? "" : "_Entwurf"}_${new Date().toLocaleString()}`
        .replaceAll("/", "_")
        .replaceAll(":", "_");

    return [response, title];
  }

  async downloadDocumentWithType(
    tenderData: TenderData,
    document: Document,
    requestType: string,
    responseType: ResponseType,
    fileExtension: string,
  ): Promise<void> {
    const [response, title] = await this.getDocument(
      tenderData,
      document,
      requestType,
      responseType,
    );

    downloadFile(response, `${title}${fileExtension}`);
  }

  async getDocumentPlaceholderPreview(
    tenderData: TenderData,
    placeholder: string,
  ): Promise<string> {
    const response = await $axios.post(
      `/tender_data/${tenderData.id}/documents/placeholder/`,
      { placeholder },
    );

    return response.data;
  }

  async getDocumentStyleSheet(tenderData: TenderData): Promise<string> {
    const response = await $axios.get(
      `/tender_data/${tenderData.id}/documents/style/`,
    );

    return response.data;
  }

  async createDocumentFolder(
    data: DocumentFolderCreate,
  ): Promise<DocumentFolder> {
    const response = await $axios.post(
      `/tender_data/${data.tender_data_id}/documents/folders/`,
      {
        name: data.name,
        marked_for_document_package:
          data.marked_for_document_package === "true",
      },
    );

    return response.data;
  }

  async updateDocumentFolder(
    tenderDataId: string,
    folderId: string,
    updatedData: DocumentFolderCreate,
  ): Promise<DocumentFolder> {
    const response = await $axios.patch(
      `/tender_data/${tenderDataId}/documents/folders/${folderId}/`,
      { ...updatedData },
    );

    return response.data;
  }

  async deleteDocumentFolder(tenderDataId: string, folderId: string) {
    const response = await $axios.delete(
      `/tender_data/${tenderDataId}/documents/folders/${folderId}/`,
    );

    return response.data;
  }

  async moveDocument(
    tenderDataId: string,
    documentId: string,
    folderID: string | null,
  ): Promise<Document> {
    const response = await $axios.patch(
      `/tender_data/${tenderDataId}/documents/${documentId}/folder/`,
      { new_folder_id: folderID },
    );

    return response.data;
  }

  async moveUploadedDocument(
    tenderDataId: string,
    documentId: string,
    folderID: string | null,
  ): Promise<UploadedDocument> {
    const response = await $axios.patch(
      `/tender_data/${tenderDataId}/documents/uploaded/${documentId}/folder/`,
      { new_folder_id: folderID },
    );

    return response.data;
  }

  async getTenderDataHtml(
    tenderData: TenderDataUpdate,
    document_id: string,
    version_id?: string,
  ): Promise<string> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/${document_id}/html/`,
      {
        params: { ...(version_id && { version_id: version_id }) },
        responseType: "text",
      },
    );

    return response.data;
  }

  async getTenderDataFonts(
    tenderData: TenderDataUpdate,
  ): Promise<FontOption[]> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/fonts/`,
    );
    return response.data;
  }

  async downloadDocumentPdf(
    tenderData: TenderData,
    document: Document,
  ): Promise<void> {
    return this.downloadDocumentWithType(
      tenderData,
      document,
      "pdf",
      "blob",
      ".pdf",
    );
  }

  async downloadDocumentDocx(
    tenderData: TenderData,
    document: Document,
  ): Promise<void> {
    return this.downloadDocumentWithType(
      tenderData,
      document,
      "docx",
      "blob",
      ".docx",
    );
  }

  async downloadDocumentXlsx(
    tenderData: TenderData,
    document: Document,
  ): Promise<void> {
    return this.downloadDocumentWithType(
      tenderData,
      document,
      "xlsx",
      "blob",
      ".xlsx",
    );
  }

  async createDocumentVersion(tenderData: TenderData, document: Document) {
    const response = await $axios.post(
      `tender_data/${tenderData.id}/documents/${document.id}/version/`,
    );
    return response.data;
  }

  async getUploadedDocumentVersion(
    tenderData: TenderData,
    document: UploadedDocument,
    version: DocumentVersion,
  ) {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/uploaded/${document.id}/version/${version.id}/`,
      { responseType: "blob" },
    );
    return response;
  }

  async restoreDocumentVersion(
    tenderData: TenderData,
    document: Document,
    version: DocumentVersion,
  ): Promise<BasicDocument> {
    const response = await $axios.patch(
      `tender_data/${tenderData.id}/documents/${document.id}/by_version/`,
      { version_id: version.id },
    );
    return response.data;
  }

  async restoreUploadedDocumentVersion(
    tenderData: TenderData,
    document: UploadedDocument,
    version: DocumentVersion,
  ): Promise<UploadedDocument> {
    const response = await $axios.patch(
      `tender_data/${tenderData.id}/documents/uploaded/${document.id}/version/${version.id}/`,
    );
    return response.data;
  }

  async updateDocumentSorting(
    tenderDataId: string,
    document_sorting: string,
    options: AxiosRequestConfig = {},
  ): Promise<TenderData> {
    const response = await $axios.patch(
      `tender_data/${tenderDataId}/document_sorting/`,
      null,
      { ...options, params: { document_sorting } },
    );
    return addIdsToTenderData(response.data);
  }

  async createDocumentPackage(
    tenderData: TenderData,
    data: DocumentPackageCreate,
  ): Promise<DocumentPackage> {
    const response = await $axios.post(
      `tender_data/${tenderData.id}/document_package/`,
      { ...data },
    );
    return response.data;
  }

  async downloadDocumentPackage(
    tenderData: TenderData,
    documentPackage: DocumentPackage,
  ) {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/document_package/${documentPackage.id}/`,
      { responseType: "blob" },
    );

    downloadFile(response, `${documentPackage.name}.zip`);
  }

  // lots
  async createLot(lot: LotCreate): Promise<Lot> {
    const response = await $axios.post(
      `tender_data/${lot.tender_data_id}/lots/`,
      lot,
    );
    return addIdsToLot(response.data);
  }

  async getLot(tenderDataId: TenderData["id"], lotId: Lot["id"]): Promise<Lot> {
    const response = await $axios.get(
      `tender_data/${tenderDataId}/lots/${lotId}/`,
    );
    return addIdsToLot(response.data);
  }

  async deleteLot(lot: LotUpdate): Promise<void> {
    return $axios.delete(`tender_data/${lot.tender_data_id}/lots/${lot.id}/`);
  }

  async updateLot(lot: LotUpdate, old?: LotUpdate): Promise<Lot> {
    const response = await $axios.patch(
      `tender_data/${lot.tender_data_id}/lots/${lot.id}/`,
      diff(lot, old),
    );
    return addIdsToLot(response.data);
  }

  /*
   ** positions
   */
  async createPosition(data: PositionCreate): Promise<Position> {
    const response = await $axios.post(
      `tender_data/${data.tender_data_id}/positions/`,
      data,
    );
    return addIdsToPosition(response.data, data.tender_data_id);
  }

  async updatePosition(
    position: PositionUpdate,
    old?: PositionUpdate,
  ): Promise<Position> {
    let changes = diff(position, old);
    let response: AxiosResponse | undefined = undefined;
    const omitKeys = ["order_no", "lot_id", "bullet_point_id"];
    if (old && omitKeys.some((key) => has(changes, key))) {
      response = await $axios.patch(
        `tender_data/${position.tender_data_id}/positions/${position.id}/parent/`,
        {
          order_no: position.order_no,
          // old_lot_id: old?.lot_id,
          new_lot_id: position.lot_id,
          // old_bullet_point_id: old?.bullet_point_id,
          new_bullet_point_id: position.bullet_point_id,
        },
      );
      changes = omit(changes, omitKeys);
    }
    if (!isEmpty(changes)) {
      response = await $axios.patch(
        `tender_data/${position.tender_data_id}/positions/${position.id}/`,
        changes,
      );
    }
    return addIdsToPosition(
      response?.data ?? position,
      position.tender_data_id,
    );
  }

  async deletePosition(position: PositionUpdate): Promise<void> {
    return $axios.delete(
      `tender_data/${position.tender_data_id}/positions/${position.id}/`,
    );
  }

  async getPosition(id: string, sd_id: string): Promise<Position> {
    return await $axios
      .get(`tender_data/${sd_id}/positions/${id}/`)
      .then((response) => addIdsToPosition(response.data, sd_id));
  }

  createPositionGroups(
    criteria_groups: ProductGroup,
    positionId: string,
    tenderDataId: string,
    specs: { id: number; feature_id: string }[] | null = null,
  ): Promise<Position> {
    return $axios
      .post(
        `tender_data/${tenderDataId}/positions/${positionId}/create_groups_and_criteria_with_specs/`,
        { criteria_groups, specs },
      )
      .then((response) => addIdsToPosition(response.data, tenderDataId));
  }

  getPositionUnitOptions(): Promise<Unit[]> {
    return $axios.get("units/").then((response) => response.data);
  }

  /** @deprecated feature no longer in use */
  getPositionUnitsByGroupName(groupName: string): Promise<Unit[]> {
    return $axios.get(`units/${groupName}/`).then((response) => response.data);
    // const response = await $axios.get(`service_template_units`);
  }

  /*
   ** criteria
   */
  createCriterion(data: CriterionCreate): Promise<Criterion> {
    return $axios
      .post(`tender_data/${data.tender_data_id}/criteria/`, data)
      .then((response) =>
        addIdsToCriterion(response.data, data.tender_data_id),
      );
  }

  async updateCriterion(
    spec: CriterionUpdate,
    old?: CriterionUpdate,
  ): Promise<Criterion> {
    let changes = diff(spec, old);
    let response: AxiosResponse | undefined = undefined;
    const omitKeys = ["order_no", "criteria_group_id"];
    if (old && omitKeys.some((key) => has(changes, key))) {
      response = await $axios.patch(
        `tender_data/${spec.tender_data_id}/criteria/${spec.id}/parent/`,
        {
          order_no: spec.order_no,
          old_criteria_group_id: old?.criteria_group_id,
          new_criteria_group_id: spec.criteria_group_id,
        },
      );
      changes = omit(changes, omitKeys);
    }
    if (!isEmpty(changes)) {
      response = await $axios.patch(
        `tender_data/${spec.tender_data_id}/criteria/${spec.id}/`,
        changes,
      );
    }
    return addIdsToCriterion(response?.data ?? spec, spec.tender_data_id);
  }

  deleteCriterion(spec: CriterionUpdate): Promise<void> {
    return $axios.delete(
      `tender_data/${spec.tender_data_id}/criteria/${spec.id}/`,
    );
  }

  /*
   ** criteria groups
   */
  createGroup(data: CriterionGroupCreate): Promise<CriterionGroup> {
    return $axios
      .post(`tender_data/${data.tender_data_id}/criteria_groups/`, data)
      .then((response) =>
        addIdsToCriteriaGroup(response.data, data.tender_data_id),
      );
  }

  updateGroup(
    spec: CriterionGroupUpdate,
    old?: CriterionGroupUpdate,
  ): Promise<CriterionGroup> {
    return $axios
      .patch(
        `tender_data/${spec.tender_data_id}/criteria_groups/${spec.id}/`,
        diff(spec, old),
      )
      .then((response) =>
        addIdsToCriteriaGroup(response.data, spec.tender_data_id),
      );
  }

  deleteGroup(spec: CriterionGroupUpdate): Promise<void> {
    return $axios.delete(
      `tender_data/${spec.tender_data_id}/criteria_groups/${spec.id}/`,
    );
  }

  async createRevision(
    tender_data_id: string,
    document_id: string,
  ): Promise<TenderData> {
    return await $axios
      .post(
        `tender_data/${tender_data_id}/documents/${document_id}/revision/`,
        {},
        { params: { api_version: 2 } },
      )
      .then((response) => addIdsToTenderData(response.data));
  }

  async createRevisions(tender_data_id: string): Promise<TenderData> {
    return await $axios
      .post(`tender_data/${tender_data_id}/revisions/`)
      .then((response) => addIdsToTenderData(response.data));
  }

  async updateRevision(
    tenderDataId: string,
    revisionId: string,
    note: Pick<DocumentRevision, "note">,
  ): Promise<TenderData> {
    return await $axios
      .patch(
        `tender_data/${tenderDataId}/documents/revision/${revisionId}/`,
        note,
      )
      .then((response) => addIdsToTenderData(response.data));
  }

  async downloadRevisionAsFile(
    tenderData: TenderData,
    revisionId: string,
    requestType: string,
    responseType: ResponseType,
    fileExtension: string,
    fileName?: string,
  ): Promise<File> {
    const response = await $axios.get(
      `tender_data/${tenderData.id}/documents/revision/${revisionId}/${requestType}/`,
      { responseType },
    );
    return new File(
      [response.data],
      `${fileName ?? tenderData.name}${fileExtension}`,
    );
  }

  async downloadRevisionWithType(
    document: Document,
    tenderData: TenderData,
    revision: DocumentRevision,
    index: number,
    requestType: string,
    responseType: ResponseType,
    fileExtension: string,
  ): Promise<void> {
    const [response, title] = await this.getDocumentRevisionAndTitle(
      tenderData,
      document,
      revision,
      requestType,
      responseType,
      index,
    );

    downloadFile(response, `${title}${fileExtension}`);
  }

  async previewRevisionPdf(tenderData: TenderData, revisionId: string) {
    return this.downloadRevisionAsFile(
      tenderData,
      revisionId,
      "pdf",
      "blob",
      ".pdf",
    );
  }

  async downloadRevisionPdf(
    tenderData: TenderData,
    document: Document,
    revision: DocumentRevision,
    index: number,
  ): Promise<void> {
    return this.downloadRevisionWithType(
      document,
      tenderData,
      revision,
      index,
      "pdf",
      "blob",
      ".pdf",
    );
  }

  async downloadRevisionDocx(
    tenderData: TenderData,
    document: Document,
    revision: DocumentRevision,
    index: number,
  ): Promise<void> {
    return this.downloadRevisionWithType(
      document,
      tenderData,
      revision,
      index,
      "docx",
      "blob",
      ".docx",
    );
  }

  async downloadRevisionXlsx(
    tenderData: TenderData,
    document: Document,
    revision: DocumentRevision,
    index: number,
  ): Promise<void> {
    return this.downloadRevisionWithType(
      document,
      tenderData,
      revision,
      index,
      "xlsx",
      "blob",
      ".xlsx",
    );
  }

  async createBulletPoint(data: BulletPointCreate): Promise<BulletPoint> {
    const response = await $axios.post(
      `tender_data/${data.tender_data_id}/bullet_points/`,
      data,
    );
    return addIdsToBulletPoint(response.data, data.tender_data_id);
  }

  async updateBulletPoint(
    data: BulletPointUpdate,
    old?: BulletPointUpdate,
  ): Promise<BulletPoint> {
    let changes = diff(data, old);
    let response: AxiosResponse | undefined = undefined;
    const omitKeys = ["order_no", "lot_id", "bullet_point_parent_id"];
    if (old && omitKeys.some((key) => has(changes, key))) {
      response = await $axios.patch(
        `tender_data/${data.tender_data_id}/bullet_points/${data.id}/parent/`,
        {
          order_no: data.order_no,
          // old_lot_id: old?.lot_id,
          new_lot_id: data.lot_id,
          // old_bullet_point_parent_id: old?.bullet_point_parent_id,
          new_bullet_point_parent_id: data.bullet_point_parent_id,
        },
      );
      changes = omit(changes, omitKeys);
    }
    if (!isEmpty(changes)) {
      response = await $axios.patch(
        `tender_data/${data.tender_data_id}/bullet_points/${data.id}/`,
        changes,
      );
    }
    return addIdsToBulletPoint(
      // TODO: Workaround for the `tender_data_id` workaround
      // because `bullet_point_children` is undefined in PATCH responses
      { ...old, ...(response?.data ?? data) },
      data.tender_data_id,
    );
  }

  async deleteBulletPoint(data: BulletPointUpdate): Promise<void> {
    const response = await $axios.delete(
      `tender_data/${data.tender_data_id}/bullet_points/${data.id}/`,
    );
    return response.data;
  }

  async addGroupsAndCriteria(
    sd_id: string,
    data: CombinedCriterionCreate,
  ): Promise<TenderData> {
    return await $axios
      .post(`tender_data/${sd_id}/add_groups_and_criteria/`, data)
      .then((response) => addIdsToTenderData(response.data));
  }

  async getImage(
    tenderDataId: string,
    documentId: string,
    imageId: string,
  ): Promise<string> {
    return $axios
      .get(
        `tender_data/${tenderDataId}/documents/${documentId}/images/${imageId}/`,
        { responseType: "blob" },
      )
      .then((response) => {
        if (!response.data) {
          throw new Error("Image not found");
        }
        return createObjectURL(response.data)!;
      });
  }

  async uploadImage(
    tenderDataId: string,
    documentId: string,
    imageId: string,
    image: File,
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append("file", image);

    const response = await $axios.post(
      `tender_data/${tenderDataId}/documents/${documentId}/images/${imageId}/`,
      formData,
    );
    return response.status === 200;
  }

  async deleteImage(
    tenderDataId: string,
    documentId: string,
    imageId: string,
  ): Promise<void> {
    await $axios.delete(
      `tender_data/${tenderDataId}/documents/${documentId}/images/${imageId}/`,
    );
  }

  async getDocumentTemplates(): Promise<DocumentTemplateListItem[]> {
    const response = await $axios.get(`document_templates/`);
    return response.data;
  }

  async createDocumentTemplateFromDocument(
    data: CreateDocumentTemplateFromDocument["request"],
  ): Promise<CreateDocumentTemplateFromDocument["response"]> {
    return (await $axios.post(`document_templates/`, data)).data;
  }

  async createDocumentFromTemplate(
    templateId: string,
    data: CreateDocumentFromTemplate["request"],
    options: AxiosRequestConfig = {},
  ): Promise<CreateDocumentFromTemplate["response"]> {
    return (
      await $axios.post(
        `document_templates/${templateId}/document/`,
        data,
        options,
      )
    ).data;
  }

  async getDocumentTemplatePreview(
    templateId: string,
    abortSignal?: AbortSignal,
  ): Promise<DocumentTemplatePreview> {
    const response = await $axios.get(
      `document_templates/${templateId}/preview/`,
      { responseType: "blob", signal: abortSignal },
    );
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    return {
      url: URL.createObjectURL(blob),
      type: response.headers["content-type"],
    };
  }

  async updateDocumentTemplate(
    templateId: string,
    request: UpdateDocumentTemplate["request"],
  ): Promise<UpdateDocumentTemplate["response"]> {
    return (await $axios.patch(`document_templates/${templateId}/`, request))
      .data;
  }

  async deleteDocumentTemplate(id: string): Promise<void> {
    await $axios.delete(`document_templates/${id}/`);
  }
}

export default new DescriptionsService();

//
// Following functions add ids to various objects. In the past these ids were set by the backend.
// This is a hotfix in search for a more permanent solutions.
//

function addIdsToCriterion(
  criterion: Criterion,
  tender_data_id: string,
): Criterion {
  return {
    ...criterion,
    tender_data_id: criterion.tender_data_id ?? tender_data_id,
  };
}

function addIdsToCriteriaGroup(
  criterionGroup: CriterionGroup,
  tender_data_id: string,
): CriterionGroup {
  return {
    ...criterionGroup,
    tender_data_id: criterionGroup.tender_data_id ?? tender_data_id,
    criteria: criterionGroup.criteria.map((c) =>
      addIdsToCriterion(c, tender_data_id),
    ),
  };
}

function addIdsToBulletPoint(
  bulletPoint: BulletPoint,
  tender_data_id: string,
  lot_id: string | null = null,
): BulletPoint {
  return {
    ...bulletPoint,
    tender_data_id: bulletPoint.tender_data_id ?? tender_data_id,
    lot_id: bulletPoint.lot_id ?? lot_id,
    bullet_point_children: bulletPoint.bullet_point_children.map((b) =>
      addIdsToBulletPoint(b, tender_data_id, lot_id),
    ),
    positions: bulletPoint.positions.map((p) =>
      addIdsToPosition(p, tender_data_id, lot_id),
    ),
  };
}

function addIdsToPosition(
  position: Position,
  tender_data_id: string,
  lot_id: string | null = null,
): Position {
  return {
    ...position,
    tender_data_id: position.tender_data_id ?? tender_data_id,
    lot_id: position.lot_id ?? lot_id,
    criteria: position.criteria.map((c) =>
      addIdsToCriterion(c, tender_data_id),
    ),
    criteria_groups: position.criteria_groups.map((g) =>
      addIdsToCriteriaGroup(g, tender_data_id),
    ),
  };
}

function addIdsToLot(lot: Lot): Lot {
  return {
    ...lot,
    bullet_points: lot.bullet_points.map((b) =>
      addIdsToBulletPoint(b, lot.tender_data_id, lot.id),
    ),
    positions: lot.positions.map((p) =>
      addIdsToPosition(p, lot.tender_data_id, lot.id),
    ),
    criteria: lot.criteria.map((c) => addIdsToCriterion(c, lot.tender_data_id)),
    criteria_groups: lot.criteria_groups.map((g) =>
      addIdsToCriteriaGroup(g, lot.tender_data_id),
    ),
  };
}

function addIdsToTenderData(sd: TenderData): TenderData {
  return {
    ...sd,
    lots: sd.lots ? sd.lots.map((l) => addIdsToLot(l)) : undefined,
    bullet_points: sd.bullet_points
      ? sd.bullet_points.map((b) => addIdsToBulletPoint(b, sd.id))
      : undefined,
    positions: sd.positions
      ? sd.positions.map((p) => addIdsToPosition(p, sd.id))
      : undefined,
    criteria: sd.criteria
      ? sd.criteria.map((c) => addIdsToCriterion(c, sd.id))
      : undefined,
    criteria_groups: sd.criteria_groups
      ? sd.criteria_groups.map((g) => addIdsToCriteriaGroup(g, sd.id))
      : undefined,
  };
}
