// URL Configuration
interface URLConfig {
  ui: string;
  auth: string;
  api: string;
  verde: string;
}

const URL_FALLBACKS: Record<string, URLConfig> = {
  production: {
    ui: "app.govradar.net",
    auth: "https://auth.govradar.net",
    api: "https://app.govradar.net/api/services",
    verde: "https://verde.govradar.net",
  },
  staging: {
    ui: "staging.govradar.net",
    auth: "https://auth.govradar.net",
    api: "https://staging.govradar.net/api/services",
    verde: "https://verde.govradar.net",
  },
  development: {
    ui: "dev.govradar.net",
    auth: "https://auth-dev.govradar.net",
    api: "https://dev.govradar.net/api/services",
    verde: "https://verde.govradar.net",
  },
  local: {
    ui: "app.localhost",
    auth: "https://auth.localhost",
    api: "https://app.localhost/api/services",
    verde: "https://verde.localhost",
  },
};

// URL Utilities
export const isFullURL = (input: string): boolean =>
  /^https?:\/\//i.test(input);
export const trimSlashesStart = (value: string): string =>
  value.replace(/^\/+/, "");
export const trimSlashesEnd = (value: string): string =>
  value.replace(/\/+$/, "");
export const trimSlashes = (value: string): string =>
  trimSlashesStart(trimSlashesEnd(value));

// Environment Detection
export const HOSTNAME =
  typeof window !== "undefined" ? window.location.hostname : "localhost:8080";

function getURLConfig(): URLConfig {
  // Check for environment variable overrides first
  const fromEnv = {
    auth: getURLFromEnv("VITE_AUTH_URL"),
    api: getURLFromEnv("VITE_API_GATEWAY_URL"),
    verde: getURLFromEnv("VITE_BMI_APP_URL"),
  };

  // Determine environment based on hostname
  let config: URLConfig;
  switch (HOSTNAME) {
    case URL_FALLBACKS.production.ui:
      config = URL_FALLBACKS.production;
      break;
    case URL_FALLBACKS.staging.ui:
      config = URL_FALLBACKS.staging;
      break;
    case URL_FALLBACKS.local.ui:
      config = URL_FALLBACKS.local;
      break;
    default:
      config =
        getEnvMode() === "development"
          ? URL_FALLBACKS.development
          : URL_FALLBACKS.production;
  }

  function getEnvMode(): string {
    try {
      return import.meta.env.MODE;
    } catch {
      return process.env.NODE_ENV ?? "development";
    }
  }

  // Override with environment variables if present
  return {
    ...config,
    auth: fromEnv.auth ?? config.auth,
    api: fromEnv.api ?? config.api,
    verde: fromEnv.verde ?? config.verde,
  };
}

function getURLFromEnv(key: keyof ImportMetaEnv): string | null {
  let value: string | undefined = undefined;
  try {
    value = import.meta.env[key];
  } catch {
    value = process.env[key];
  }
  if (!value) {
    return null;
  }
  const valid = URL.canParse(value);
  return valid ? trimSlashesEnd(new URL(value).toString()) : null;
}

// Public API
export function getKeycloakURL(): string {
  return getURLConfig().auth;
}

export function getGatewayURL(): string {
  return getURLConfig().api;
}

export function makeBMIAppUrl(path?: string): string {
  const base = getURLConfig().verde;
  return base + "/#/" + (path ?? "");
}

export function buildURL(input: string): string {
  if (isFullURL(input)) {
    return input;
  }
  const apiGatewayURL = getGatewayURL();
  const normalizedPath = trimSlashes(input);
  return `${apiGatewayURL}/${normalizedPath}/`;
}
