import Aura from "@primeuix/themes/aura";
import { definePreset } from "@primeuix/themes";

// Define the preset based on Aura theme with custom colors
export default definePreset(Aura, {
  semantic: {
    // Primitive color tokens based on the application's color palette
    primary: {
      50: "#EEF1FB",
      100: "#E2E7F8",
      200: "#C1CCF1",
      300: "#A4B4EA",
      400: "#879CE3",
      500: "#6A85DD",
      600: "#4969D5",
      700: "#2F53CB",
      800: "#2847AE",
      900: "#213A8F", // govdark
      950: "#101D46",
    },
    formField: {
      focusRing: {
        width: "1px",
        style: "solid",
        color: "{primary.activeColor}",
      },
    },
    colorScheme: {
      light: {
        surface: {
          0: "#ffffff",
          50: "{gray.50}",
          100: "{gray.100}",
          200: "{gray.200}",
          300: "{gray.300}",
          400: "{gray.400}",
          500: "{gray.500}",
          600: "{gray.600}",
          700: "{gray.700}",
          800: "{gray.800}",
          900: "{gray.900}",
          950: "{gray.950}",
        },
        primary: {
          color: "{primary.800}",
          contrastColor: "#ffffff",
          hoverColor: "{primary.900}",
          activeColor: "{primary.900}",
        },
        highlight: {
          background: "#c7d2fe",
          focusBackground: "#c7d2fe",
          color: "{primary.900}",
          focusColor: "#ffffff",
        },
        formField: {
          disabledBackground: "{surface.50}",
          disabledColor: "{surface.300}",
          placeholderColor: "{surface.400}",
          floatLabelColor: "{surface.400}",
        },
      },
    },
  },
});
