<template>
  <Transition
    enter-active-class="transition duration-300 ease-in-out"
    enter-from-class="-translate-x-full"
    enter-to-class=""
    leave-active-class="transition duration-300 ease-in-out"
    leave-from-class=""
    leave-to-class="-translate-x-full"
  >
    <SideBar
      v-if="sideBarStore.isOpen && !route.meta.disableSidebar"
      ref="sideBarRef"
      :navigation-items="navigation"
      :current-area="currentArea"
      class="mt-12 !z-30"
    />
  </Transition>
  <BreadcrumbsBar v-if="!route.meta.disableHeaderBar" :pages="breadcrumbs">
    <template #before>
      <div
        class="flex w-[88px] lg:w-sidebar items-center flex-shrink-0 transition-all duration-300"
      >
        <NavBarButton
          v-if="!route.meta.disableSidebar"
          ref="sideBarButtonRef"
          class="shrink-0"
          :class="useMobileLayout ? 'ml-2' : 'ml-4'"
          icon-size="lg"
          :icon="
            useMobileLayout
              ? Bars3Icon
              : sideBarStore.isOpen
                ? PanelLeftContractIcon
                : PanelLeftExpandIcon
          "
          :aria-label="
            sideBarStore.isOpen ? 'Sidebar schließen' : 'Sidebar öffnen'
          "
          @click="toggleDrawer"
        />
        <router-link :to="breadcrumbs.at(0)?.to ?? '/'" class="mx-auto px-0.5">
          <img
            class="h-9 w-9 object-cover object-left lg:w-[216px] lg:object-fill lg:object-center transition-[width] duration-300"
            src="/govradar_rgb.svg"
            alt="GovRadar Logo"
          />
        </router-link>
      </div>
    </template>
  </BreadcrumbsBar>
  <main
    class="px-6 lg:px-8 z-0 accent-govdark overflow-x-clip transition-all duration-300 ease-in-out"
    :class="{
      'h-full': route.meta.disableGlobalScroll,
      'ml-sidebar':
        !useMobileLayout && sideBarStore.isOpen && !route.meta.disableSidebar,
    }"
  >
    <router-view :key="route.path" is-router-view @navigate="navigate" />
  </main>
  <Teleport to="body">
    <Alerts />
  </Teleport>
  <ModalFree
    v-model="showAnnouncements"
    disable-title
    width="max-w-screen-lg rounded-xl"
  >
    <ReleaseAnnouncements />
  </ModalFree>
</template>

<script lang="ts" setup>
import Alerts from "@/components/AlertList.vue";
import { useScreenHelpers } from "@/helpers/screensize";
import { useAuthStore } from "@/stores/auth";
import { useFeatureStore } from "@/stores/feature";
import { SideBar } from "@govradar/vue-components";
import {
  BuildingStorefrontIcon,
  DocumentTextIcon,
  HomeIcon,
  LightBulbIcon,
  ListBulletIcon,
} from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import { type RouteLocationRaw, useRoute, useRouter } from "vue-router";
import useBreadcrumbs from "@/composables/useBreadcrumbs";
import BreadcrumbsBar from "@/components/BreadcrumbsBar.vue";
import { Bars3Icon } from "@heroicons/vue/24/solid";
import PanelLeftContractIcon from "~icons/fluent/panel-left-contract-24-regular";
import PanelLeftExpandIcon from "~icons/fluent/panel-left-expand-24-regular";
import { onClickOutside } from "@vueuse/core";
import { useSidebarStore } from "@/stores/sidebar";
import NavBarButton from "@/components/NavBarButton.vue";
import { useAnalytics } from "./an4lytics";
import ReleaseAnnouncements from "@/components/announcements/ReleaseAnnouncements.vue";
import ModalFree from "@/components/ModalFree.vue";
import { storeToRefs } from "pinia";
import { useReleaseAnnouncementStore } from "@/stores/announcements";

type DrawerStatus =
  | "explicitly-opened"
  | "explicitly-closed"
  | "automatically-opened"
  | "automatically-closed";

const route = useRoute();
const router = useRouter();

const { useMobileLayout } = useScreenHelpers();
const sideBarStore = useSidebarStore();

if (!useMobileLayout) {
  sideBarStore.openSidebar();
}
const navigationDrawerStatus = ref<DrawerStatus>(
  sideBarStore.isOpen ? "automatically-opened" : "automatically-closed",
);

const sideBarRef = ref<HTMLElement | null>(null);
const sideBarButtonRef = ref<HTMLElement | null>(null);

const navigate = (to: RouteLocationRaw) => {
  router.push(to);
};

const closeSidebarAutomatically = () => {
  if (sideBarStore.isOpen) {
    sideBarStore.closeSidebar();
    navigationDrawerStatus.value = "automatically-closed";
  }
};

const openSidebarAutomatically = () => {
  if (!sideBarStore.isOpen) {
    sideBarStore.openSidebar();
    navigationDrawerStatus.value = "automatically-opened";
  }
};

watch(useMobileLayout, (useMobile) => {
  if (useMobile) {
    closeSidebarAutomatically();
  } else if (navigationDrawerStatus.value === "automatically-closed") {
    openSidebarAutomatically();
  }
});

// Close drawer when clicked outside during mobile view
onClickOutside(
  sideBarRef,
  () => {
    if (useMobileLayout.value) {
      sideBarStore.closeSidebar();
      navigationDrawerStatus.value = "explicitly-closed";
    }
  },
  { ignore: [sideBarButtonRef] },
);

function toggleDrawer() {
  sideBarStore.toggleSidebar();

  navigationDrawerStatus.value = sideBarStore.isOpen
    ? "explicitly-opened"
    : "explicitly-closed";
}

// Close sidebar when navigating on "mobile"
watch(
  () => route.path,
  () => {
    if (useMobileLayout.value) closeSidebarAutomatically();
  },
  { immediate: true },
);

const authStore = useAuthStore();
const featureStore = useFeatureStore();

const breadcrumbs = useBreadcrumbs();

//highlight area which user is currently in
const currentArea = computed(() => {
  const path = route.path;
  if (path === "/start") return "Startseite";
  if (path.startsWith("/vergabeunterlagen"))
    return featureStore.flags.tender_data
      ? "Öffentliche Unterlagen"
      : "Vergabeunterlagen";

  if (path.startsWith("/produkt")) return "Lieferleistungen";
  if (path.startsWith("/dienstleistung")) return "Dienstleistungen";
  if (path.startsWith("/beschaffungsvorhaben")) {
    return featureStore.flags.tender_data
      ? "Beschaffungsvorhaben"
      : "Leistungsbeschreibungen";
  }
  if (path.startsWith("/ausschreibungen")) return "Ausschreibungen";
  if (path.startsWith("/verde/contract")) return "Dokumente";
  if (path.startsWith("/verde/template")) return "Vorlagen";
  return "";
});

const navigation = computed(() => {
  return [
    {
      type: "link",
      size: "bold",
      to: { name: "start" },
      text: "Startseite",
      icon: HomeIcon,
    },

    {
      type: "divider",
    },

    ...(featureStore.flags.tender_data
      ? [
          {
            type: "text",
            text: "Bedarf beschreiben",
            size: "bold",
            icon: ListBulletIcon,
          },

          {
            type: "link",
            to: { name: "tender-data-list" },
            text: featureStore.flags.tender_data
              ? "Beschaffungsvorhaben"
              : "Leistungsbeschreibungen",
            size: "small",
          },

          {
            type: "divider",
          },
        ]
      : []),

    {
      type: "text",
      text: "Wissen entdecken",
      size: "bold",
      icon: LightBulbIcon,
    },
    {
      type: "link",
      to: { name: "vergabeunterlagen" },
      text: featureStore.flags.tender_data
        ? "Öffentliche Unterlagen"
        : "Vergabeunterlagen",
      size: "small",
      licenseDisabled: !authStore.licenses.tenders_license,
      urlToRedirect: import.meta.env.VITE_GOVRADAR_FEATURE_INFOS,
    },

    {
      type: "divider",
    },

    {
      type: "text",
      text: "Markt erkunden",
      size: "bold",
      icon: BuildingStorefrontIcon,
    },
    {
      type: "link",
      to: { name: "criteria-search1" },
      text: "Lieferleistungen",
      size: "small",
      licenseDisabled: !authStore.licenses.markets_license,
      urlToRedirect: import.meta.env.VITE_GOVRADAR_FEATURE_INFOS,
    },

    {
      type: "divider",
    },

    ...(!featureStore.flags.tender_data
      ? [
          {
            type: "text",
            text: "Bedarf beschreiben",
            size: "bold",
            icon: ListBulletIcon,
          },

          {
            type: "link",
            to: { name: "tender-data-list" },
            text: featureStore.flags.tender_data
              ? "Beschaffungsvorhaben"
              : "Leistungsbeschreibungen",
            size: "small",
          },

          {
            type: "divider",
          },
        ]
      : []),

    {
      type: "text",
      text: featureStore.flags.tender_data
        ? "Verträge & Formblätter"
        : "Dokumente erstellen",
      size: "bold",
      icon: DocumentTextIcon,
    },
    {
      type: "link",
      to: { name: "verde" },
      text: "Dokumente",
      size: "small",
      licenseDisabled: !authStore.licenses.documents_writer_license,
      urlToRedirect: import.meta.env.VITE_GOVRADAR_FEATURE_INFOS,
    },
    {
      type: "link",
      to: { name: "verde-own-templates" },
      text: "Vorlagen",
      size: "small",
      licenseDisabled: !authStore.licenses.documents_designer_license,
      urlToRedirect: import.meta.env.VITE_GOVRADAR_FEATURE_INFOS,
    },

    {
      type: "divider",
      hidden: !featureStore.flags.gpt4_ui,
    },

    {
      type: "link",
      to: { name: "gpt4-ui" },
      text: "GPT-4 UI",
      size: "small",
      hidden: !featureStore.flags.gpt4_ui,
    },
  ].filter((d) => !d.hidden);
});
// Analytics
const analytics = useAnalytics();
analytics?.enableAutoTracking();

const { showAnnouncements } = storeToRefs(useReleaseAnnouncementStore());
</script>
