import type { Document, UploadedDocument } from "@/types/tenderData";

export function generateCopyName(
  fileName: string,
  collisionFileNames: string[] = [],
): string {
  const copyRegex = /\((Kopie(?: (\d+))?)\)$/;

  let newFileName: string;

  // Check if filename is already a copy
  if (copyRegex.test(fileName)) {
    // Extract the copy number and increment it
    newFileName = fileName.replace(copyRegex, (match, copyText, number) => {
      const nextNumber = number ? parseInt(number) + 1 : 2;
      return `(Kopie ${nextNumber})`;
    });
  } else {
    newFileName = `${fileName} (Kopie)`;
  }

  // Check if the new filename is already in use
  if (collisionFileNames.includes(newFileName)) {
    return generateCopyName(newFileName, collisionFileNames);
  }

  return newFileName;
}

export function cutFileNameSuffix(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf(".");
  return lastDotIndex > 0 ? fileName.slice(0, lastDotIndex) : fileName;
}

const invalidFileNameRegExp = /[^a-zA-Z0-9-_ ]/g;

export function hasInvalidFileName(fileName: string) {
  invalidFileNameRegExp.lastIndex = 0;
  return invalidFileNameRegExp.test(fileName);
}

export function formatFileName(fileName: string) {
  invalidFileNameRegExp.lastIndex = 0;
  return fileName.replace(invalidFileNameRegExp, "");
}

export function getDownloadName(document: Document | UploadedDocument) {
  if (document.download_name?.length) {
    return document.download_name;
  } else {
    return formatFileName(document.name);
  }
}
