import logger from ".";
import { type AppConfig } from "vue";

export function useAutoLogging() {
  // Set up global error handlers
  window.onerror = (message, source, lineno, colno, error) => {
    const body = {
      message: `Uncaught error: ${message}`,
      source,
      lineno,
      colno,
      stack: error?.stack || "No stack trace available",
      exceptionType: error?.name || "Frontend Error",
    };
    logger.error(body);
  };

  window.onunhandledrejection = (event) => {
    const error =
      event.reason instanceof Error
        ? event.reason
        : new Error(String(event.reason));
    const body = {
      message: `Unhandled promise rejection: ${error.message}`,
      stack: error.stack || "No stack trace available",
      exceptionType: error?.name || "Frontend Error",
    };
    logger.error(body);
  };
}

export const vueErrorHandler: AppConfig["errorHandler"] = (
  error,
  instance,
  info,
) => {
  const err = error instanceof Error ? error : new Error(String(error));
  logger.error({
    message: `Vue error: ${err.message}. Info: ${info}`,
    source: instance?.$options.name,
    stack: err.stack,
    exceptionType: err.name || "Vue Error",
  });
};
