import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from "axios";
import { useAlertStore } from "@/stores/alert";
import { useAuthStore } from "@/stores/auth";
import { buildURL, trimSlashesEnd } from "./urls";

/*** Handle these Error codes manually */
const IGNORED_ERRORS = [409, 422];
const IGNORED_ERROR_MESSAGES = ["Objekt/Ressource 'Dokument' nicht gefunden."];

export function forceTrailingSlashUrl<T extends AxiosRequestConfig>(config: T) {
  const [path, query] = String(config.url).split("?", 2);
  let requestUrl = path;
  if ((config.method ?? "GET").toUpperCase() !== "GET") {
    requestUrl = trimSlashesEnd(requestUrl) + "/";
  }
  if (query) {
    requestUrl += `?${query}`;
  }
  if (config.url !== requestUrl) {
    console.warn(`Rewriting request URL "${config.url}" to "${requestUrl}"`);
    config.url = requestUrl;
  }
  return config;
}

function createErrorAlert(
  text: string,
  heading?: string,
  traceId?: string,
  spanId?: string,
  timestamp?: string,
) {
  const alertStore = useAlertStore();
  alertStore.createAlert({
    type: "error",
    heading: heading ? heading : "Fehler",
    message: text,
    traceId: traceId,
    spanId: spanId,
    timestamp: timestamp,
  });
}

function setupDefaultAxios(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(forceTrailingSlashUrl);
  axiosInstance.interceptors.request.use(function (config) {
    const authStore = useAuthStore();
    if (authStore.token) {
      config.headers = {
        ...config.headers,
        Authorization: authStore.token,
      };
    }
    return config;
  });
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosError) {
      const authStore = useAuthStore();
      const title = error.response?.data.title || null;
      const paramErrors = error.response?.data.param_errors || null;
      const status = error.response?.status || null;
      const timestamp =
        error.response?.data.additional_info?.["@timestamp"] || null;
      const traceId =
        error.response?.data.additional_info?.["trace.id"] || null;
      const spanId = error.response?.data.additional_info?.["span.id"] || null;

      // this happens on network errors
      if (status === null) {
        // alertStore.createAlert({
        //   type: "error",
        //   heading: "Netzwerkfehler",
        //   message: "Es ist leider ein Fehler aufgetreten.",
        // });
      }
      // this happens when the token expires
      else if (status === 401) {
        if (title)
          createErrorAlert(title, "Fehler", traceId, spanId, timestamp);
        else
          createErrorAlert(
            "Ihr letzter Login ist mehr als einen Tag her, wir haben Sie daher zur Ihrer Sicherheit und der Ihrer Daten ausgeloggt. Bitte loggen Sie sich erneut ein um fortzufahren.",
            "Fehler",
            traceId,
            spanId,
            timestamp,
          );
        authStore.logout();
      }
      // form error
      else if (status === 422) {
        // only catch if it is not formatted properly
        if (paramErrors === null || paramErrors.length === 0) {
          createErrorAlert(
            "Es ist leider ein Fehler aufgetreten.",
            "Eingabefehler",
            traceId,
            spanId,
            timestamp,
          );
        }
      }
      // a backend api error
      else if (
        status &&
        status >= 400 &&
        !IGNORED_ERRORS.includes(status) &&
        !IGNORED_ERROR_MESSAGES.includes(title)
      ) {
        if (title) {
          createErrorAlert(title, "Fehler", traceId, spanId, timestamp);
        } else
          createErrorAlert(
            "Es ist leider ein Fehler aufgetreten.",
            "Unbekannter Fehler",
            traceId,
            spanId,
            timestamp,
          );
      }
      // return
      return Promise.reject(error);
    },
  );
}

export function setupFastApiAxios(axiosInstance: AxiosInstance) {
  setupDefaultAxios(axiosInstance);
}

export function createAndSetupAxios(baseUrl: string) {
  const instance = axios.create({
    baseURL: buildURL(baseUrl),
  });

  setupFastApiAxios(instance);

  return instance;
}
