import {
  LoggerProvider,
  BatchLogRecordProcessor,
  LogRecordExporter,
} from "@opentelemetry/sdk-logs";
import { Logger, SeverityNumber } from "@opentelemetry/api-logs";
import { Resource } from "@opentelemetry/resources";
import { OTLPLogExporter } from "@opentelemetry/exporter-logs-otlp-http";
import { CompressionAlgorithm } from "@opentelemetry/otlp-exporter-base";
import { buildURL, trimSlashesEnd, HOSTNAME } from "../helpers/urls";
import { v4 as uuidv4 } from "uuid";

export interface LogMessage {
  message: string;
  source?: string;
  lineno?: number;
  colno?: number;
  stack?: string;
  exceptionType?: string;
}

export interface Logging {
  debug(message: LogMessage): void;
  info(message: LogMessage): void;
  warn(message: LogMessage): void;
  error(message: LogMessage): void;
}

function createOTELLogger(): Logger {
  const exporter = makeOTELExporter();
  const resource = makeOTELResource();
  const loggerProvider = new LoggerProvider({ resource });
  loggerProvider.addLogRecordProcessor(new BatchLogRecordProcessor(exporter));
  return loggerProvider.getLogger("default", "1.0.0");
}

function makeOTELExporter(): LogRecordExporter {
  return new OTLPLogExporter({
    url: trimSlashesEnd(
      buildURL(import.meta.env.VITE_OTEL_COLLECTOR_LOGS_ROUTE),
    ),
    compression: CompressionAlgorithm.GZIP,
  });
}

function makeOTELResource(): Resource {
  const env = HOSTNAME === "app.govradar.net" ? "PROD" : "DEV";
  return new Resource({
    "service.name": "govradar-frontend",
    "host.name": HOSTNAME,
    "deployment.environment": env,
    "service.environment": env,
  });
}

function formatMessage(message: LogMessage): string {
  return `[${message.exceptionType}] ${message.message}${
    message.source ? `\nSource: ${message.source}` : ""
  }${message.lineno ? `\nLine: ${message.lineno}` : ""}${
    message.colno ? `\nColumn: ${message.colno}` : ""
  }${message.stack ? `\nStack: ${message.stack}` : ""}`;
}

class OTLPLogging implements Logging {
  private logger: Logger;

  constructor() {
    this.logger = createOTELLogger();
  }

  info(message: LogMessage): void {
    this.logger.emit({
      severityNumber: SeverityNumber.INFO,
      severityText: "INFO",
      body: formatMessage(message),
    });
  }
  warn(message: LogMessage): void {
    this.logger.emit({
      severityNumber: SeverityNumber.WARN,
      severityText: "WARN",
      body: formatMessage(message),
    });
  }
  error(message: LogMessage): void {
    this.logger.emit({
      severityNumber: SeverityNumber.ERROR,
      severityText: "ERROR",
      body: formatMessage(message),
      attributes: {
        "error.id": uuidv4(),
        "error.exception.type": message.exceptionType,
      },
    });
  }
  debug(message: LogMessage): void {
    this.logger.emit({
      severityNumber: SeverityNumber.DEBUG,
      severityText: "DEBUG",
      body: formatMessage(message),
    });
  }
}

class ConsoleLogging implements Logging {
  debug(): void {}
  info(): void {}
  warn(message: LogMessage): void {
    console.warn(formatMessage(message));
  }
  error(message: LogMessage): void {
    if (message.exceptionType) {
      console.error(`Exception Type: ${message.exceptionType}`);
    }
    console.error(formatMessage(message));
  }
}

function makeLogging(): Logging {
  const isLocal = HOSTNAME === "localhost" || import.meta.env.DEV;
  return isLocal || !import.meta.env.VITE_LOGGING_ENABLED
    ? new ConsoleLogging()
    : new OTLPLogging();
}

const logger = makeLogging();

export default logger;
