import { InjectionKey, inject } from "vue";

export interface Analytics {
  /// Log an event with the given name and data
  trackEvent<E extends EventName>(eventName: E, data: EventDataMap[E]): void;
  /// Enable automatic tracking of events like button clicks
  enableAutoTracking(): void;
}

export const injectionKey = Symbol() as InjectionKey<Analytics>;

//To define new events, extend this type and update any related mappings to until typescript is happy
export type EventName =
  | "tender_copied_to_clipboard"
  | "tender_downloaded"
  | "tender_searched"
  | "tender_opened_document_preview"
  | "tender_closed_document_preview"
  | "tender_bookmarked"
  | "tender_searched_document"
  | "tender_copied_from_bookmark_to_clipboard"
  | "button_click"
  | "service_description_downloaded"
  | "document_research_assistant_text_selected"
  | "tender_data_assistant_content_inserted"
  | "ai_edited_content_inserted";

/// Returns the matomo category of an event
export function getEventCategory(eventName: EventName): EventCategory {
  return EventCategoriesMap[eventName];
}

export type EventCategory = "service_descriptions" | "tenders" | "auto" | "ai";

const EventCategoriesMap: Record<EventName, EventCategory> = {
  tender_copied_to_clipboard: "tenders",
  tender_downloaded: "tenders",
  tender_searched: "tenders",
  tender_opened_document_preview: "tenders",
  tender_closed_document_preview: "tenders",
  tender_bookmarked: "tenders",
  tender_searched_document: "tenders",
  tender_copied_from_bookmark_to_clipboard: "tenders",
  button_click: "auto",
  service_description_downloaded: "service_descriptions",
  document_research_assistant_text_selected: "ai",
  tender_data_assistant_content_inserted: "ai",
  ai_edited_content_inserted: "ai",
};

type EventData = {
  [k: string]: string | number | null;
};

export type EventDataMap = Record<EventName, EventData> & {
  tender_copied_to_clipboard: {
    query: string;
    content: string;
    search_id: string | null;
    document_id: string | null;
  };
  tender_downloaded: { tender_id: string; tender_name: string };
  tender_searched: {
    query: string;
    search_id: string;
    question_filter_active: boolean;
    topic_filter_active: boolean;
    filter_value: string | null;
  };
  tender_opened_document_preview: {
    document_id: string;
    from_bookmark: boolean;
  };
  tender_closed_document_preview: {
    document_id: string;
    from_bookmark: boolean;
  };
  tender_bookmarked: {
    tender_id: string;
    bookmark_id: string | null;
    type: string;
    document_id: string | null;
  };
  tender_searched_document: {
    search_term: string;
    document_id: string;
    tender_id: string;
  };
  tender_copied_from_bookmark_to_clipboard: {
    from: string;
    text: string;
    tender_id: string;
    document_id: string;
  };
  button_click: { title: string };
  tender_data_downloaded: {
    tender_data_id: string;
    document_type: string;
    format: string;
  };
  document_research_assistant_text_selected: { text: string; question: string };
  tender_data_assistant_content_inserted: {
    tender_data_id: string;
    function: "continue_writing" | "headline_suggestion";
  };
  ai_edited_content_inserted: {
    function:
      | "bulletPoints"
      | "flowing"
      | "lengthen"
      | "shorten"
      | "summarize"
      | "professional";
  };
};

export function useAnalytics(): Analytics | undefined {
  return inject(injectionKey);
}
