import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { UserManual } from "@/types/userManual";
import { IAMService } from "@/services";
import {
  BuildingStorefrontIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
  LightBulbIcon,
  ListBulletIcon,
} from "@heroicons/vue/24/outline";
import { groupBy } from "es-toolkit";
import { compareOrder } from "@/helpers/calculateOrderNumber";
import { useFeatureStore } from "@/stores/feature";

export const useUserManualStore = defineStore("userManualStore", () => {
  /** Flat list of all userManuals */
  const userManuals = ref<UserManual[] | null>(null);

  /** UserManuals grouped by categories */
  const groupedUserManuals = computed(() => {
    if (!userManuals.value) return null;
    const filteredManuals = userManuals.value
      .filter((m) => !m.disabled)
      .sort(compareOrder);
    const manualMap = groupBy(filteredManuals, (item) => item.app_section);
    const manualGroups = [
      {
        name: "Wissen entdecken",
        icon: LightBulbIcon,
        size: 1,
        sections: [
          {
            name: useFeatureStore().flags.tender_data
              ? "Öffentliche Unterlagen"
              : "Vergabeunterlagen",
            manuals: manualMap["assistant"],
          },
        ],
      },
      {
        name: "Markt erkunden",
        icon: BuildingStorefrontIcon,
        size: 1,
        sections: [
          {
            name: "Lieferleistungen",
            manuals: manualMap["markets"],
          },
        ],
      },
      {
        name: "Bedarf beschreiben",
        icon: ListBulletIcon,
        size: 2,
        sections: [
          {
            name: "Leistungsbeschreibungen",
            manuals: manualMap["tenders"],
          },
        ],
      },
      {
        name: useFeatureStore().flags.tender_data
          ? "Verträge & Formblätter"
          : "Dokumente erstellen",
        icon: DocumentTextIcon,
        size: 1,
        sections: [
          {
            name: "Dokumente",
            manuals: manualMap["documents_writer"],
          },
          {
            name: "Vorlagen",
            manuals: manualMap["documents_designer"],
          },
        ],
      },
      {
        name: "Einstellungen",
        icon: Cog6ToothIcon,
        size: 1,
        sections: [
          {
            name: "Allgemein",
            manuals: manualMap["settings"],
          },
        ],
      },
    ];
    return manualGroups
      .map((manualGroup) => ({
        ...manualGroup,
        sections: manualGroup.sections.filter((s) => s.manuals?.length),
      }))
      .filter((manualGroup) => manualGroup.sections?.length);
  });

  /** Loading state */
  const loading = ref(false);

  /** Reloads the manuals from backend */
  async function updateManuals() {
    loading.value = true;
    userManuals.value = await IAMService.getUserManuals();
    loading.value = false;
  }

  /** Load the manuals if not already loaded */
  async function initializeManuals() {
    if (!userManuals.value && !loading.value) await updateManuals();
  }

  return {
    userManuals,
    groupedUserManuals,
    loading,
    updateManuals,
    initializeManuals,
  };
});
